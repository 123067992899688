$env: 1;
@import "../../../../@core/scss/base/bootstrap-extended/include";
@import "../../../../@core/scss/base/components/include";

.signature {
  border: 2px solid;
  border-color: $primary;
  border-radius: 5px;

  width: 100%;
  height: 100px;
  overflow: hidden;

  &-canvas {
    width: 100%;
    height: 99px;
  }
}

.stepper-text-style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #1c2346;
  text-align: center;
  margin: 0;
}

.steper-plus-minus {
  display: flex;
  height: 30px;
  width: 30px;
  font-size: 30px;
  margin: 0;
  background-color: #f3f3f3;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.steper-plus-minus:hover {
  transform: scale(1.1);
}

.chevron {
  cursor: pointer;
  margin-right: 10px;
}

.chevron:hover {
  transform: scale(1.1);
}

.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    width: 100%;
    .picker-field {
      width: 100%;
      border-radius: 5px;
      height: 40px;
      border: 1px solid #d8d6de;
      padding-left: 15px;
      color: #6e6b7b;
      &:focus-visible {
        outline: unset;
        border-color: #B6A884;
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
      }
      &::placeholder {
        color: #bcbcc0;
      }
    }
  }
}

.date-picker-input {
  border-radius: 5px;
  padding: 4px 12px;
  background-color: white;
  height: 30px;
  border: 1px solid gainsboro;
  // box-shadow: 0 0 2px #0074d9;
}

// .date-picker-input:focus {
//   outline: none;
//   border: 1px solid #0c8af8;
//   box-shadow: 0 0 10px 2px #0074d9;
// }
.date-picker-container {
  position: relative;
}

.exclamation {
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  right: 11px;
  cursor: pointer;
}


.calendar-icon {
  position: absolute;
  top: 50%; /* Adjust this value to vertically center the icon */
  right: 10px; /* Adjust this value to control the distance between the icon and the input */
  transform: translateY(-50%);
  cursor: pointer;
}
.form-label  {
  color: #4F4F4F;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.form-control {
  border-radius: 8px !important;
border: 1px solid var(--black-1, #CFD3D4) !important;
}
.on-disable {
  border: 1px solid var(--black-1, #EFEFEF) !important;
  cursor: not-allowed !important;
}
.css-k3ockt-control {
  border-radius: 8px !important;
border: 1px solid var(--black-1, #CFD3D4) !important;

}
.card {
  margin-bottom: 0.6rem  !important;
  border-radius: 8px !important;
// background: #FFF !important;
// box-shadow: 0px 7px 25px 0px rgba(198, 196, 228, 0.10) !important;
}
.react-select__placeholder {
  color: red !important; /* Change this to the desired color */
}
.select__multi-value {
  border-radius: 20px !important;
border: 1px solid #EEE !important;
background: #F3F3F3 !important;
box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06) !important;
color: #3F3F3F !important;

.select__multi-value__label {
  color: #000 !important;
font-family: Inter !important;
font-size: 12px !important;
font-style: normal  !important;
font-weight: 400 !important;
line-height: normal !important;

}

}
