$env: 1;
.event-list-filter {
  background-color: white;
  // display: flex;
  // justify-content: start;
  // align-items: center;
  // gap: 10px;
  margin: 5px 0px 20px 0px;
  padding: 20px 20px 5px 20px;
  border-radius: 10px;
  // margin-bottom: 0;
  .event-list-filter-child {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .date-pick {
      margin-top: 12px;
    }
  
    .filter-input-fields {
      background-color: #f4f5f6 !important;
      height: 38px !important;
      border-radius: 8px !important;
      box-shadow: none !important;
      .select__control {
        // background-color: black;
        margin: 0 !important;
        height: 40px !important;
        border-radius: 8px !important;
        background-color: #f4f5f6;
      }
    }
    .filter-input-fields-multi {
      background-color: #f4f5f6;
      // height: 40px !important;
      border-radius: 8px !important;
      box-shadow: none !important;
      .select__control {
        // background-color: black;
        margin: 0 !important;
        // height: 40px !important;
        border-radius: 8px !important;
        background-color: #f4f5f6;
      }
    }
  }

  .couple-buttons {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  
}
.select__placeholder {
  color: #aeafaf !important;
}

.reset {
  background-color: #b0a782 !important;
  border: none !important;
}
