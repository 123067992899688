$env: 1;
@import "../../../@core/scss/base/bootstrap-extended/include";
@import "../../../@core/scss/base/components/include";

.signature {
  // border: 2px solid;
  // border-color: $primary;
  // border-radius: 5px;

  width: 50%;
  height: 100px;
  overflow: hidden;

  &-canvas {
    width: 100%;
    height: 99px;
  }
}

#AuditDetails {
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 39px;
    color: #261d56;
  }
}

#user-details {
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #6e6b7b;
  }

  .item-value {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #6e6b7b;
  }
}
.main-section {
  .heading {
    margin-top: 30px;
    color: #000;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
  }
  .image-header {
    margin: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 10px;

      .priority-pill {
        font-family: Inter;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 9px 20px;
        border-radius: 5px;
        margin-left: 10px !important;
      }
      .Low {
        background: #f5f8e2 !important;
        color: #c5c903 !important;
      }

      .Medium {
        background: #f8efe2 !important;
        color: #ee9520 !important;
      }
      .High {
        background: #fff0ef;
        color: #d75249 !important;
      }
      .important-chip {
        color: #919395;
        font-family: Inter;
        font-size: 12px;
        font-weight: 700;
        border-radius: 5px;
        border: 0.5px solid gainsboro;
        padding: 8px 20px;
        width: fit-content;
        margin-left: 20px;
        cursor: pointer !important;
        &-danger {
          color: #d75249;
          font-family: Inter;
          font-size: 12px;
          font-weight: 700;
          border-radius: 5px;
          border: 0.5px solid #d75249;
          padding: 8px 20px;
          width: fit-content;
          margin-left: 20px;
          cursor: pointer !important;
        }
      }
    }

    .submit {
      background-color: #04433d !important;
      border: none !important;
      border-radius: 5px;
      color: #fff;
      font-family: Inter;
      font-size: 12px !important;
      font-weight: 500;
      width: 120px !important;
    }
  }
  &_info-container {
    display: flex;
    // flex-wrap: nowrap;
    &_info {
      border: 1px solid #e2e2ea;
      min-height: 60px;
      display: flex;
      flex-direction: column;
      padding-left: 30px;
      justify-content: center;
      gap: 5px;
      .info-head {
        color: #919395;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }

      .info-content {
        line-break: anywhere;
        color: #161719;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
      }
    }
    &_file {
      margin: 0px 10px;
      width: 170px;
      border: 1px solid #e2e2ea;
      border-radius: 5px;
      .name {
        display: flex;
        align-items: center;
        // padding-left: 5px;
        figure {
          margin-top: 15px;
        }
        .title {
          margin: 10px 10px !important;
        }
      }
    }
  }
}
#medication-details {
  // h2 {
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 20px;
  //   line-height: 39px;
  //   color: #261d56;
  // }
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #000000;
  }
}

.pharmacy-available {
  background-color: $menu-dark-bg-color;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 5px;
  &-content {
    background-color: #fafafafa;
    padding: 10px;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: $menu-dark-bg-color;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.badge-show {
  // background-color: red;
  padding: 2px 4px;
  color: white;
  border-radius: 7px;
  font-weight: 500;
  font-size: 11px;
}

.dottedBorderTop {
  border-top: 1px dotted black;
}

.row-head {
  background-color: #f6f5f4 !important;
  .info-head {
    font-size: 13px !important;
    color: black !important;
  }
}

.header-of-section {
  background-color: #dad9d9;
  padding: 20px;
  border: #e2e2ea solid 1px;
  margin: 20px -15px 0px -15px !important;
}
