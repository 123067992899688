$env: 1;
@import "../../../../@core/scss/base/bootstrap-extended/include";
@import "../../../../@core/scss/base/components/include";

.signature {
  border: 2px solid;
  border-color: $primary;
  border-radius: 5px;

  width: 100%;
  height: 100px;
  overflow: hidden;

  &-canvas {
    width: 100%;
    height: 99px;
  }
}

.stepper-text-style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #1c2346;
  text-align: center;
  margin: 0;
}

.steper-plus-minus {
  display: flex;
  height: 30px;
  width: 30px;
  font-size: 30px;
  margin: 0;
  background-color: #f3f3f3;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.steper-plus-minus:hover {
  transform: scale(1.1);
}

.chevron {
  cursor: pointer;
  margin-right: 10px;
}

.chevron:hover {
  transform: scale(1.1);
}

.custom-tooltip {
  background-color: #343a40 !important; // Dark background
  color: #fff !important; // White text
  font-size: 14px;
  padding: 10px;
  border-radius: 8px;
  max-width: 620px; // Set max width
  width: 620px; // Fixed width
  text-align: left;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  line-height: 1.5;
  white-space: normal; // Allow text wrapping
}

.custom-tooltip::before {
  border-top-color: #343a40 !important; // Match tooltip background color
}


.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    width: 100%;
    .picker-field {
      width: 100%;
      border-radius: 5px;
      height: 40px;
      border: 1px solid #d8d6de;
      padding-left: 15px;
      color: #6e6b7b;
      &:focus-visible {
        outline: unset;
        border-color: #b6a884;
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
      }
      &::placeholder {
        color: #bcbcc0;
      }
    }
  }
}

.date-picker-input {
  border-radius: 5px;
  padding: 4px 12px;
  background-color: white;
  height: 30px;
  border: 1px solid gainsboro;
  // box-shadow: 0 0 2px #0074d9;
}

// .date-picker-input:focus {
//   outline: none;
//   border: 1px solid #0c8af8;
//   box-shadow: 0 0 10px 2px #0074d9;
// }
.date-picker-container {
  position: relative;
}

.calendar-icon {
  position: absolute;
  top: 50%; /* Adjust this value to vertically center the icon */
  right: 10px; /* Adjust this value to control the distance between the icon and the input */
  transform: translateY(-50%);
  cursor: pointer;
}
.form-label {
  color: #4f4f4f;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.form-control {
  border-radius: 8px !important;
  border: 1px solid var(--black-1, #cfd3d4) !important;
}
.on-disable {
  border: 1px solid var(--black-1, #efefef) !important;
  cursor: not-allowed !important;
}
.css-k3ockt-control {
  border-radius: 8px !important;
  border: 1px solid var(--black-1, #cfd3d4) !important;
}
.card {
  margin-bottom: 0.6rem !important;
  border-radius: 8px !important;
  // background: #FFF !important;
  // box-shadow: 0px 7px 25px 0px rgba(198, 196, 228, 0.10) !important;
}
.react-select__placeholder {
  color: red !important; /* Change this to the desired color */
}
.step {
  // pointer-events: none;
}
